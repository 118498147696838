import { Logger } from "packs/common"
import { START_PROCESS, END_PROCESS } from "../store/types"
import { reactive } from "vue"

interface _Notice {
    message: string

    setMessage(mes: string)
    deleteMessage()
    stateStart()
    stateEnd()
}

//  データストア
let Notice: _Notice = reactive({
    // フラッシュで表示させるメッセージを入れます。
    message: null,

    setMessage(mes) {
        Logger(`Notice.setMessage: ${mes}`)
        this.message = mes
    },
    deleteMessage() {
        this.message = null
    },
    stateStart() {
        this.state = START_PROCESS
    },
    stateEnd() {
        this.state = END_PROCESS
    },
})
export default Notice
