
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"

// コンポーネントを読込.

// モデルを読込.

@Options({
    components: {},
})
export default class MdProgressBar extends Vue {}
