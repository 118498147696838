
import { Options, Prop, Vue, Emit } from "vue-property-decorator"
import { Logger } from "packs/common"

@Options({
    components: {},
})
export default class RadioButtonTag extends Vue {
    @Prop()
    options
    @Prop()
    defaultOption: string
    @Prop()
    optionName: string
    @Prop({ default: 100 })
    limit: number
    @Prop()
    type: string // 横並び=horizon, 縦並び=vertical(default)

    @Emit()
    select(val) {}

    public selectId(e) {
        // Logger(`update value has called. ${e.target.value}`);
        this.select(e.target.value)
    }
}
