
import { Options, Prop, Ref, Vue } from "vue-property-decorator"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"

@Options({
    components: {},
})
export default class MdTextfieldTag extends Vue {
    @Prop()
    value: string
    @Prop()
    type: string
    @Prop()
    name: string
    @Prop()
    placeholder: string
    @Prop()
    isDisabled: boolean
    @Prop()
    required: boolean
    @Prop()
    errMessage: string
    @Prop()
    fieldStyle: string
    @Prop()
    maxLength: string
    @Prop()
    min: number
    @Prop()
    max: number

    @Ref()
    mdfield

    addtionalClass = ``
    onetimeId = Util.getRandomColor()

    public updateValue(e) {
        this.$emit("inputValue", e.target.value)
    }

    public click(e) {
        this.$emit("clickTextfield", e.target)
        this.addtionalClass = `md-focused`
    }

    public focusInput(selectAll = false) {
        if (this.mdfield && this.mdfield.$el) {
            this.mdfield.$el.focus()
            if (selectAll) {
                this.mdfield.$el.select()
            }
        }
    }

    public focusout() {
        this.$emit("blur")
        this.addtionalClass = ``
    }

    public pressEnter(keycode: number) {
        Logger(`pressEnter ${keycode}`)
        // 日本語入力中のエンターを弾く処理.
        if (keycode !== 13) return
        this.$emit(`enter`)
    }
}
