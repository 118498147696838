
import { Options, Prop, Vue } from "vue-property-decorator"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

@Options({
    components: {},
})
export default class TextareaTag extends Vue {
    @Prop()
    name: string
    @Prop()
    id: string
    @Prop()
    placeholder: string
    @Prop()
    value: string
    @Prop()
    maxlength: number
    @Prop()
    retValue: string

    public updateValue(e) {
        // Logger(`update value has called.${e.target.value}`);
        if (Util.isPresent(this.retValue)) {
            this.$emit("inputValue", e.target.value, this.retValue)
            return
        }
        this.$emit("inputValue", e.target.value)
    }

    public focusout() {
        this.$emit("blur")
    }

    public click(e) {
        this.$emit("clickTextarea", e.target)
    }
}
