import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name", "placeholder", "id", "value", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("textarea", {
    style: {"resize":"none","outline":"none"},
    name: _ctx.name,
    placeholder: _ctx.placeholder,
    id: _ctx.id,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
    value: _ctx.value,
    maxlength: _ctx.maxlength ? _ctx.maxlength : 10000,
    onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focusout && _ctx.focusout(...args))),
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
  }, null, 40, _hoisted_1))
}