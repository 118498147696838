import { Logger } from "packs/common"
import RoomMessage from "packs/models/RoomMessage"
import store from "../store"
import { gtagClick } from "packs/GoogleTagManager"
import Notice from "packs/models/Notice"
import RoomManager from "packs/models/RoomManager"

// declare var activeStatus: string //focus=このページにきた, blur=はずれた

//  便利系を記載.
export default class NotificationControl {
    static readonly notificationTypeFixed = `FIXED` // 確定時
    static readonly notificationTypeSuggested = `SUGGESTED` // 日程を提案した時
    static readonly notificationTypeOpenPage = `OPEN_PAGE` // ページを開いた時
    static readonly notificationTypeSelectTime = `SELECT_TIME` // 日程を選択した時

    static createAndPushMessage(message: RoomMessage) {
        Logger(`createAndPushMessage status: ${store.state.activeStatus}`)
        Notification.requestPermission()

        // 許可されていなければ通知不要.
        if (Notification.permission !== "granted") return

        // ページを開いていたら通知不要です.
        if (store.state.activeStatus == `focus`) return

        const owner = message.owner

        const text = owner ? `${owner.name}: ${message.message}` : `${message.message}`
        const iconImage =
            owner && owner.image_url && owner.image_url.length > 0 ? owner.image_url : "/assets/logo/notificationLogo512.png" // Chrome用
        let n = new Notification(`新着メッセージ`, {
            body: text,
            icon: iconImage,
        })
        const _event = function () {
            gtagClick(`Notification 新着メッセージ`, text)
            window.focus()
            this.close()
        }
        n.addEventListener("click", _event)
        n.addEventListener("mousedown", _event)
        n.addEventListener("touchstart", _event)
        n.addEventListener("mouseup", _event)
        n.addEventListener("touchend", _event)

        setTimeout(n.close.bind(n), 5000)
    }

    static notifyParent(type: string, params: any) {
        Logger(`埋め込み形式の場合に、親要素に確定通知します: ${type}`)
        let _params = JSON.stringify(params)
        let ref = window.parent
        ref.postMessage({ type: type, message: _params }, `*`)
    }

    static showErrorMessage(err: any) {
        const res = err.response ? err.response.data : null
        if (!res) return

        let msg = ""

        if (res.messages) {
            msg = res.messages.join(", ")
        } else if (res.message) {
            msg = res.message
        }
        Notice.setMessage(msg)
    }
}
