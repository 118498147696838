import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["type", "name", "placeholder", "value", "id", "disabled", "required", "maxlength", "min", "max"]
const _hoisted_3 = { class: "md-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`md-field md-theme-default ${_ctx.placeholder ? `md-has-placeholder` : ``} ${
            _ctx.errMessage ? `md-invalid` : ``
        } ${_ctx.addtionalClass} ${_ctx.isDisabled ? `md-disabled` : ``} ${_ctx.value ? `md-has-value` : ``}`),
    style: _normalizeStyle(_ctx.fieldStyle)
  }, [
    _createElementVNode("label", {
      class: "fs12 w100",
      for: `md-input-${_ctx.onetimeId}`
    }, _toDisplayString(_ctx.name), 9, _hoisted_1),
    _createElementVNode("input", {
      type: _ctx.type,
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      style: _normalizeStyle(_ctx.fieldStyle),
      value: _ctx.value,
      class: "md-input",
      id: `md-input-${_ctx.onetimeId}`,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.focusout && _ctx.focusout(...args))),
      disabled: _ctx.isDisabled ? `true` : null,
      required: _ctx.required ? `true` : false,
      maxlength: _ctx.maxLength ? _ctx.maxLength : null,
      min: _ctx.min ? _ctx.min : 0,
      max: _ctx.max,
      ref: `mdfield`,
      onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.pressEnter($event.keyCode)), ["enter","up","native"]))
    }, null, 44, _hoisted_2),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.errMessage), 1)
  ], 6))
}