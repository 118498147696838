// import Vue from "vue"
import { createStore } from "vuex"

// Vue.use(Vuex)

export default createStore({
    state: {
        activeStatus: ``,
    },
    mutations: {
        updateBlur(state) {
            state.activeStatus = `blur`
        },
        updateFocus(state) {
            state.activeStatus = `focus`
        },
    },
    actions: {
        start(context) {
            $(window).on("blur focus", function (e) {
                var prevType = $(this).data("prevType")
                if (prevType != e.type) {
                    switch (e.type) {
                        case "blur":
                            // console.log(`blurがよばれました`)
                            context.commit(`updateBlur`)
                            break
                        case "focus":
                            // console.log(`focusがよばれました`)
                            context.commit(`updateFocus`)
                            break
                    }
                }
                $(this).data("prevType", e.type)
            })
        },
    },
})
